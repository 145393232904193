<template>
  <section class="hero-section" ref="tournament_hero">
    <div class="inner-container">
      <div class="tournament-hero">
        <img class="tournament-hero--bg" :src="TOURNAMENT.background" alt="Not found">
        <div class="tournament-hero--content">
          <div class="tournament-hero--top">
            <router-link to="/" class="to-groups">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 19L8 12L15 5" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
              </svg>
              К группе турниров
            </router-link>
            <div class="title">{{ TOURNAMENT.attributes.name }}</div>
            <div class="desc">Турнир с призовым фондом {{ TOURNAMENT.attributes.prize_fund }}</div>
          </div>
          <div class="tournament-hero--bot">

            <template v-if="participateButtonStatus">
              <div v-if="showRegBtn" class="button" @click="participateInTournament(TOURNAMENT)">Принять участие</div>
            </template>
            <div class="sgl-button sgl-button--empty" @click="declineEntry" v-if="canUserDecline(TOURNAMENT)">Отменить участие</div>

            <div class="info">
              <div class="info-row">
                <span class="label">игровая дисциплина:</span>
                <img class="absolute-entity" :src="this.GAMES.find(el => String(el.id) === String(TOURNAMENT.game.id)).logo" alt="Not found">
              </div>
              <div v-if="getTournamentStartedAtBySeconds(TOURNAMENT) > TOURNAMENT.attributes.current_timestamp" class="info-row">
                <span class="label">начнётся через:</span>
                <span class="absolute-entity">
                  <count-down-timer
                      :seconds="getTournamentStartDistance(TOURNAMENT)"
                      @ended="updateTournament()"
                  />
                </span>
              </div>
              <div v-else class="info-row">
                <span class="label">{{ getTournamentStatus(tournament) }}</span>
              </div>
              <div class="info-row">
                <span class="label">Участники:</span>
                <span class="absolute-entity">{{ TOURNAMENT.attributes.participants_count }} / {{ TOURNAMENT.attributes.count_of_teams }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <tournament-registration
        :tournament="TOURNAMENT"
        :composition="currentComposition"
        v-if="isModalOpened"
        @closeModal="isModalOpened = false"/>
    <simple-text-modal v-if="GET_SHOW_THANKS_IS_SIMPLE_MODAL_OPEN" />
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import getTimezone from "@/otherJS/getTimezone";
import {tournamentInfoMixin} from "@/otherJS/mixins/tournamentInfoMixin";
import SimpleTextModal from "@/components/modals/simple-text-modal";
import TournamentRegistration from "@/components/modals/tournament-registration/tournament-registration";
import CountDownTimer from "@/components/CountDownTimer/CountDownTimer";

export default {
  name: "tournamentHero",
  components: {CountDownTimer, TournamentRegistration, SimpleTextModal},
  mixins: [getTimezone, tournamentInfoMixin],
  props: {
    tournament: Object,
    groupId: Number
  },
  computed: {
    ...mapGetters([
      'GAMES',
      'TOURNAMENT',
      'PARTICIPANTS',
      'AUTH',
      'USER',
      'USER_MANAGED_TEAMS',
      'GET_SHOW_THANKS_IS_SIMPLE_MODAL_OPEN'
    ]),
  },
  methods: {
    ...mapActions([
      'IS_ENTRY_CREATED',
      'CREATE_TOURNAMENT_ENTRIE',
      'DECLINE_ENTRY'
    ]),
    openRegistrationModal() {
      this.$emit('registerMe')
    },
    hideRegBtnOnEvent() {
      this.showRegBtn = false;
    }
  },
  mounted() {
    window.addEventListener('tournament-entry-created', this.hideRegBtnOnEvent);
  },
  beforeDestroy() {
    window.removeEventListener('tournament-entry-created', this.hideRegBtnOnEvent);
  }
}
</script>

<style scoped>

</style>