<template>
  <div class="sgl-single-event" v-if="dataReady">

    <tournament-hero :tournament="currentTournament"/>

    <div class="inner-container">
      <div class="navigation-panel sgl-navigation-panel">
        <nav class="navigation-panel__wrapper">
          <ul>
            <li>
              <router-link exact-active-class="active" :to="{ name: 'tournaments.info'}">Общее</router-link>
            </li>
            <li>
              <router-link exact-active-class="active" :to="{ name: 'tournaments.teams'}">
                {{ TOURNAMENT.attributes.participant_type === 'TEAM' ? 'Команды' : 'Игроки' }}
              </router-link>
              <span>
                {{ TOURNAMENT.attributes.participants_count }}
                </span>
            </li>
            <li v-if="isNavItemsShowed">
              <router-link active-class="active" :to="{ name: 'tournaments.brackets'}">
                Таблица
              </router-link>
            </li>
            <li v-if="isNavItemsShowed">
              <router-link exact-active-class="active" :to="{ name: 'tournaments.matches'}">Матчи</router-link>
            </li>
            <li>
              <router-link exact-active-class="active" :to="{ name: 'tournaments.rules'}">Правила</router-link>
            </li>
            <li v-if="isWinnersShowed">
              <router-link exact-active-class="active" :to="{name: 'tournaments.winners'}">Победители</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="inner-container">
      <transition name="default">
        <router-view/>
      </transition>
    </div>

    <scroll-top/>
  </div>
  <div v-else style="margin-top: 100px">
    <sgl-events-groups-skeleton/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {tournamentInfoMixin} from "@/otherJS/mixins/tournamentInfoMixin";
import moment from "moment";
import ScrollTop from "@/components/UIElements/scrollTop";
import TournamentHero from "@/components/tournaments/singleTournament/tournamentHero";
import _ from "lodash";
import {ActiveEcho as PublicEcho} from "@/main";
import SglEventsGroupsSkeleton from "@/views/sponsor-tournament/sglEventsGroups-skeleton";

export default {
  name: "sglSingleEvent",
  components: {SglEventsGroupsSkeleton, TournamentHero, ScrollTop},
  mixins: [tournamentInfoMixin],
  data() {
    return {
      openTrack: false,
      currentTournament: {},
      dataReady: false
    };
  },
  computed: {
    ...mapGetters([
      'TOURNAMENTS',
      'TOURNAMENT',
      'PARTICIPANTS',
      'USER',
      'AUTH',
      'USER_MANAGED_TEAMS',
      'USER_CAPTAIN_TEAMS',
      'CAN_U_GET_DATA_AFTER_USER_AUTH'
    ]),
    toggleActive() {
      return this.openTrack ? 'active' : '';
    },
    isNavItemsShowed() {
      if (moment(this.TOURNAMENT.attributes.started_at).valueOf() < moment().valueOf()) {
        return this.PARTICIPANTS.length !== 0;
      }
      return false;
    },
    isWinnersShowed() {
      return this.TOURNAMENT.attributes.finished_at !== null;

    },
    canUGetTournamentData() {
      return this.CAN_U_GET_DATA_AFTER_USER_AUTH;
    }
  },
  watch: {
    async canUGetTournamentData(nV, oV) {
      if (nV) {
        await this.init();
      }
    }
  },
  methods: {
    ...mapActions([
      'GET_TOURNAMENT_BY_ID',
    ]),

    async init() {
      await this.GET_TOURNAMENT_BY_ID({id: this.$route.params.id});
      this.currentTournament = this.TOURNAMENT;
      this.dataReady = true;
    },

    /**
     * Обновляем данные по турниру, не чще 1 раза в секунду
     * */
    updateTournament: _.debounce(async function () {
      await this.GET_TOURNAMENT_BY_ID({id: this.$route.params.id, silently: true});
    }, 60000, {'leading': true, 'trailing': false}),

    /**
     * Подписываемся на ивенты обновления турнира
     * */
    subscribeToTournamentUpdate() {
      PublicEcho.channel(`tournament.${this.$route.params.id}`)
          .listen('.MatchesChanged', ev => {
            this.updateTournament();
          })
          .listen('.TournamentChanged', ev => {
            this.updateTournament();
          })
          .listen('.TournamentIncomingEntriesChanged', ev => {
            this.updateTournament();
          });
    }
  },

  created() {
    if (this.CAN_U_GET_DATA_AFTER_USER_AUTH) {
      this.init();
    }
  },

  mounted() {
    this.subscribeToTournamentUpdate();
  },

  beforeDestroy() {
    PublicEcho.leave(`tournament.${this.$route.params.id}`);
  }
};
</script>

<style scoped>

</style>
